import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Session from './service/Session'
import Auth from './pago-rapido/Page/Auth'
import Dashboard from './pago-rapido/Page/Dashboard'
import DetailPago from './pago-rapido/Components/DetailPago';
import PagoRapidoKhipuSuccess from './pago-rapido/Components/PagoRapidoKhipuSuccess';
import PagoRapidoKhipuError from './pago-rapido/Components/PagoRapidoKhipuError';
import "./pago-rapido/Assets/css/new-style.scss";
const renderContent = (props) => {
    return (
        <BrowserRouter>
            <Route exact path="/detail/:origen/:buy_order/:token/:status/:code" component={DetailPago} />
            {Session.isAuth() ?
                <Switch>
                    <Route exact path={"/dashboard"} component={Dashboard} />
                    <Route exact path={"/"} component={Auth} />
                    <Route exact path={"/auth/"} component={Auth} />
                    <Route exact path={"/payment/khipu/success"} component={PagoRapidoKhipuSuccess} />
					<Route exact path={"/payment/khipu/Error"} component={PagoRapidoKhipuError} />
                </Switch>
                :
                <>
                    <Route path={"/auth/"} component={Auth} />
                    <Redirect to={"/auth/"} />
                </>
            }
        </BrowserRouter>
    );
}

export default renderContent;